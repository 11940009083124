<!--
File: TitleList.vue
Description: form for generating Titlelist with the WorkList component.
-->
<template>
  <VueTabs @tab-change="handleTabChange">
    <v-tab id="tab0" :title="$t('tabs.budget_allocation')">
    </v-tab>

    <v-tab id="tab1" :title="$t('tabs.select_project')">
    </v-tab>

    <v-tab id="tab2" :title="$t('tabs.budget_approval')">
      <!--BudgetApprovals /-->
    </v-tab>

    <v-tab id="tab3" :title="$t('tabs.title_list')">
      <PrintTitleList @itemAssigned="itemAssigned" :step="active" />
    </v-tab>

      <Budgets v-show="showBudget" :step="active" /> <!--@showTreatments="showTreatmentsWithAssignedBudget" /-->
  </VueTabs>
</template>
<script>
  import { VueTabs, VTab } from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'

  import PrintTitleList from './PrintTitleList'
  //import Panels from '@/pages/Dashboard/Components/Panels'
  import Budgets from './Budgets.vue'
  import BudgetApprovals from './BudgetApprovals.vue'


  export default {
    name: 'title-list-creation',
    data: () => ({
      active: 'third',
      showBudget: true,
      stepNames: ['first', 'second', 'third', 'fourth'],
      //stepNames: ['third', 'new', 'fourth', 'fifth'],
    }),

    components: {
      VueTabs,
      VTab,
      PrintTitleList,
      Budgets,
      BudgetApprovals,
      //Panels
    },

    mounted() {
      // check whether plans are assigned
      this.reloadWorksLists()
    },

    methods: {
      handleTabChange(tabIndex, newTab, oldTab) {
        this.active = this.stepNames[tabIndex]
        
        this.showBudget = tabIndex != 3
        //this.reloadWorksLists()
      },

      reloadWorksLists() {
        const params = {
      //    is_approved: this.active === 'fifth' ? 1 : 0
        }
        console.log('reloadWorksLists, active, params', this.active, params)

        this.$store.dispatch('LOAD_WORKS_LISTS', params).then(() => {
        })
      },

      itemAssigned(type, itemId) {
        //console.log(`${type} ${itemId == -1 ? ' was un' : ' with the no itemId was '}assigned`)
        this.reloadWorksLists()
      },

    },

    computed: {
      savedWorksList() {
        return this.$store.state.TitleList.worksLists
      },
    },
    
    watch: {
      active(value) {
        //console.log('active', value)
      },
    }
  }
</script>

<style lang="scss" scoped></style>
